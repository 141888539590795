import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../../store/store"

const SessionTimelineSlice = createSlice({
    name: "sessionTimeline",
    initialState: {sessionUid: null, authRef: null} as {
        sessionUid: null | string | undefined
        authRef: null | string | undefined
    },
    reducers: {
        setSessionTimelineDetails: (
            state,
            {
                payload: {sessionUid, authRef},
            }: PayloadAction<{
                sessionUid: null | string | undefined
                authRef: null | string | undefined
            }>
        ) => {
            state.sessionUid = sessionUid
            state.authRef = authRef
        },
    },
})

export const {setSessionTimelineDetails} = SessionTimelineSlice.actions

export default SessionTimelineSlice.reducer
export const getSessionTimelineDetails = (state: RootState) => state.sessionTimeline
