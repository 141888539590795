import {configureStore} from "@reduxjs/toolkit"
import auth from "../pages/Login/authSlice"
import common from "../pages/Common/commonSlice"
import toast from "../components/Toaster/ToasterSlice"
import error404 from "../components/Table/404/Error404Slice"
import error500 from "../components/Table/500/Error500Slice"
import sessionExpired from "../components/SessionExpired/SessionExpiredSlice"
import error401 from "../components/401/Error401Slice"
import user from "../pages/CompanyManagement/features/Profile/userSlice"
import stationDetails from "../pages/Locations/Services/StationDetailsSlice"
import bulkChargerDetails from "../pages/Locations/Features/Chargers/Features/Services/bulkChargersSlice"
import modules from "../pages/Common/modulesSlice"
import sessionTimeline from "../pages/Sessions/Services/SessionTimelineSlice"
import settlementsDetails from "../pages/CompanyManagement/features/Settlements/Services/SettlementsSlice"
import {rtkQueryErrorLogger} from "./ErrorLogger"
import {mainSplitApi} from "../api"

export const store = configureStore({
    reducer: {
        [mainSplitApi.reducerPath]: mainSplitApi.reducer,
        auth,
        toast,
        error404,
        user,
        common,
        error500,
        stationDetails,
        bulkChargerDetails,
        modules,
        settlementsDetails,
        sessionExpired,
        error401,
        sessionTimeline,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(mainSplitApi.middleware)
            .concat(rtkQueryErrorLogger),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
