import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {setAlertLoading} from "./ToasterSlice"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

type ToasterProps = {
    vertical: "top" | "bottom"
    horizontal: "center" | "left" | "right"
    autoHideDuration: number
}

const Toaster = (props: ToasterProps) => {
    const {vertical, horizontal, autoHideDuration} = props
    const [isOpen, setIsOpen] = useState(false)
    const {alert_type, alert_msg, alert_loading} = useSelector((store: any) => store.toast)
    const dispatch = useDispatch()

    const handleClose = () => {
        setIsOpen(!isOpen)
    }
    useEffect(() => {
        if (alert_loading === true) {
            setIsOpen(true)
            setTimeout(() => {
                dispatch(setAlertLoading(false))
            }, autoHideDuration)
        } else {
            setIsOpen(false)
        }
    }, [alert_loading])

    const handleAlertType = () => {
        if (alert_type !== "" || alert_msg !== "") {
            return (
                <Alert onClose={() => handleClose()} severity={alert_type}>
                    {alert_msg}
                </Alert>
            )
        } else {
            return <></>
        }
    }

    return (
        <Snackbar anchorOrigin={{vertical, horizontal}} open={isOpen} key={vertical + horizontal}>
            {handleAlertType()}
        </Snackbar>
    )
}

export default Toaster
